import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
const getData = graphql`
  {
    c: allContentfulFiche {
      nodes {
        name
        id
        component {
          name
          reference {
            name
            id
          }
        }
      }
    }
  }
`
const DinauxEnfantMariage = ({ data }) => {
  const cData = useStaticQuery(getData)

  const enfant = cData.c.nodes
    .filter(i => i.id === data)
    .map(i => i.component.filter(t => t.name[0] === "enfant")[0])
    .map(u => u.reference)[0]
  return (
    <div className="born ongletContainer">
      <h2 className="ongletTitle">Enfant</h2>
      <div className="ongletContent">
        {enfant.map((item, index) => {
          return (
            <div className="enfant" key={index}>
              <div className="reference">
                <Link to={`/fiche/${item.id}`}>{item.name}</Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DinauxEnfantMariage
