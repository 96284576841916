import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
const getData = graphql`
  {
    c: allContentfulFiche {
      nodes {
        name
        id
        component {
          name
          fiche {
            name
            id
          }
          reference {
            name
            id
          }
        }
      }
    }
  }
`
const DinauxParent = ({ data }) => {
  const cData = useStaticQuery(getData)
  const mom = cData.c.nodes.filter(i => i.id === data)
  let dad = ""
  if (mom[0].component.filter(i => i.name[0] === "mariage").length > 0) {
    dad = mom[0].component.filter(i => i.name[0] === "mariage")[0].fiche
  }
  if (mom[0].component.filter(i => i.name[0] === "conjoint").length > 0) {
    dad = mom[0].component.filter(i => i.name[0] === "conjoint")[0].fiche
  }
  return (
    <div className="born ongletContainer">
      <h2 className="ongletTitle">Parents</h2>
      <div className="ongletContent">
        <div className="parent">
          {dad.map((item, index) => {
            return (
              <div className="pere" key={index}>
                <Link to={`/fiche/${item.id}`}>{item.name}</Link>
              </div>
            )
          })}
          {mom.map((item, index) => {
            return (
              <div className="mere" key={index}>
                <Link to={`/fiche/${item.id}`}>{item.name}</Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default DinauxParent
