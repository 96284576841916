import React from "react"
import { Link } from "gatsby"
const Death = ({ data }) => {
  const born = data.component.filter(i => i.name[0] === "dateDeath")[0]
  return (
    <div className="born ongletContainer">
      <h2 className="ongletTitle">{born.title}</h2>
      <div className="ongletContent">
        {born.date ? <div className="date">{born.date}</div> : ""}
        {born.lieux ? (
          <div>
            à <Link to={`/lieux/${born.lieux.id}`}>{born.lieux.ville}</Link> (
            {born.lieux.pays})
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default Death
