import React from "react"
import { Link } from "gatsby"

const WifeEnfant = ({ data }) => {
  const enfant = data.component.filter(i => i.name[0] === "enfant")[0].reference
  return (
    <div className="born ongletContainer">
      <h2 className="ongletTitle">Enfants</h2>
      <div className="ongletContent">
        {enfant.map((item, index) => {
          return (
            <div className="enfant" key={index}>
              <Link to={`/fiche/${item.id}`}>{item.name}</Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default WifeEnfant
