import React from "react"
import { Link } from "gatsby"

const DinauxConjoint = ({ data }) => {
  const conjoint = data.component.filter(i => i.name[0] === "conjoint")
  return (
    <div className="born ongletContainer">
      <h2 className="ongletTitle">{conjoint[0].title}</h2>
      <div className="ongletContent">
        {conjoint.map((item, index) => {
          return (
            <div className="conjoint" key={index}>
              <div className="reference">
                avec
                <Link to={`/fiche/${item.reference[0].id}`}>
                  {item.reference[0].name}
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DinauxConjoint
