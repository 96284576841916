import React from "react"
import { Link } from "gatsby"

const NotDinauxMariage = ({ data }) => {
  const mariage = data.component.filter(i => i.name[0] === "mariage")
  return (
    <div className="born ongletContainer">
      <h2 className="ongletTitle">{mariage[0].title}</h2>
      <div className="ongletContent">
        {mariage.map((item, index) => {
          return (
            <div className="mariage" key={index}>
              <div className="date">{item.date}</div>
              <div className="reference">
                avec
                <Link to={`/fiche/${item.fiche[0].id}`}>
                  {item.fiche[0].name}
                </Link>
              </div>
              {item.lieux ? (
                <div className="lieux">
                  à{" "}
                  <Link to={`/lieux/${item.lieux.id}`}>{item.lieux.ville}</Link>{" "}
                  ({item.lieux.pays})
                </div>
              ) : (
                ""
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NotDinauxMariage
