import React from "react"

const Custom = ({ data }) => {
  const custom = data.component.filter(i => i.name[0] === "custom")[0]
  return (
    <div className="born ongletContainer">
      <h2 className="ongletTitle">{custom.title}</h2>
      <div className="ongletContent">
        {custom.childContentfulComponentContentTextNode ? (
          <div className="date">
            {custom.childContentfulComponentContentTextNode.content}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default Custom
