import React from "react"
import { graphql } from "gatsby"
/*
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Img from "gatsby-image"

import { BLOCKS, INLINES } from "@contentful/rich-text-types"


const options = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: node => (
      <Link to={`/${node.data.target.slug}`}>{node.content[0].value}</Link>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => <Img {...node.data.target} />,
  },
}
*/

//component
import Born from "../components/fiches/Born"
import Death from "../components/fiches/Death"
//component Mariage
import DinauxMariage from "../components/fiches/DinauxMariage"
import NotDinauxMariage from "../components/fiches/NotDinauxMariage"
//component Conjoint
import DinauxConjoint from "../components/fiches/DinauxConjoint"
//component Enfant
import WifeEnfant from "../components/fiches/WifeEnfant"
import DinauxEnfantMariage from "../components/fiches/DinauxEnfantMariage"
//component Parent
import DinauxParent from "../components/fiches/DinauxParent"
//component Custom
import Custom from "../components/fiches/Custom"
//Menu
import Menu from "../components/Menu"
//css
import "../css/nav.scss"
const Fiche = ({ data }) => {
  let mariage = 0
  let conjoint = 0
  return (
    <div className="fiche">
      <div className="navTree">
        <fieldset>
          <h2>Navigation</h2>
          <Menu />
        </fieldset>
      </div>
      <h2>{data.c.name}</h2>

      <div className="onglet">
        {data.c.oldestfiche !== null ? (
          <>
            <h4>oldest</h4>
            {data.c.component.map((item, index) => {
              if (item.name[0] === "dateBorn") {
                return <Born data={data.c} key={index} />
              }
              if (item.name[0] === "dateDeath") {
                return <Death data={data.c} key={index} />
              }
              if (item.name[0] === "mariage") {
                mariage++
                if (mariage === 1) {
                  return (
                    <div key={index}>
                      <DinauxMariage data={data.c} />
                      {item.reference
                        .map(i => i.component)[0]
                        .filter(i => i.name[0] === "enfant").length > 0 ? (
                        <DinauxEnfantMariage data={item.reference[0].id} />
                      ) : (
                        ""
                      )}
                    </div>
                  )
                }
              }
              if (item.name[0] === "conjoint") {
                conjoint++
                if (conjoint === 1) {
                  return (
                    <div key={index}>
                      <DinauxConjoint data={data.c} />
                      {item.reference
                        .map(i => i.component)[0]
                        .filter(i => i.name[0] === "enfant").length > 0 ? (
                        <DinauxEnfantMariage data={item.reference[0].id} />
                      ) : (
                        ""
                      )}
                    </div>
                  )
                }
              }
              if (item.name[0] === "custom") {
                return <Custom data={data.c} key={index} />
              }

              return true
            })}
          </>
        ) : data.c.notDinaux === true ? (
          data.c.gender[0] === "femme" ? (
            <>
              <h4>wife dinaux</h4>
              {data.c.component.map((item, index) => {
                if (item.name[0] === "dateBorn") {
                  return <Born data={data.c} key={index} />
                }
                if (item.name[0] === "dateDeath") {
                  return <Death data={data.c} key={index} />
                }
                if (item.name[0] === "mariage") {
                  mariage++
                  if (mariage === 1) {
                    return <NotDinauxMariage data={data.c} key={index} />
                  }
                }
                if (item.name[0] === "enfant") {
                  return <WifeEnfant data={data.c} key={index} />
                }
                if (item.name[0] === "custom") {
                  return <Custom data={data.c} key={index} />
                }

                return true
              })}
            </>
          ) : (
            <>
              <h4>husband dinaux </h4>
              {data.c.component.map((item, index) => {
                if (item.name[0] === "dateBorn") {
                  return <Born data={data.c} key={index} />
                }
                if (item.name[0] === "dateDeath") {
                  return <Death data={data.c} key={index} />
                }
                if (item.name[0] === "mariage") {
                  mariage++
                  if (mariage === 1) {
                    return <NotDinauxMariage data={data.c} key={index} />
                  }
                }
                if (item.name[0] === "custom") {
                  return <Custom data={data.c} key={index} />
                }
                return true
              })}
            </>
          )
        ) : data.c.gender[0] === "homme" ? (
          <>
            <h4>boy dinaux</h4>
            {data.c.component.map((item, index) => {
              if (item.name[0] === "dateBorn") {
                return <Born data={data.c} key={index} />
              }
              if (item.name[0] === "dateDeath") {
                return <Death data={data.c} key={index} />
              }
              if (item.name[0] === "mariage") {
                mariage++

                if (mariage === 1) {
                  return (
                    <div key={index}>
                      <DinauxMariage data={data.c} />
                      {item.reference
                        .map(i => i.component)[0]
                        .filter(i => i.name[0] === "enfant").length > 0 ? (
                        <DinauxEnfantMariage data={item.reference[0].id} />
                      ) : (
                        ""
                      )}
                    </div>
                  )
                }
              }
              if (item.name[0] === "conjoint") {
                conjoint++
                if (conjoint === 1) {
                  return (
                    <div key={index}>
                      <DinauxConjoint data={data.c} />
                      {item.reference
                        .map(i => i.component)[0]
                        .filter(i => i.name[0] === "enfant").length > 0 ? (
                        <DinauxEnfantMariage data={item.reference[0].id} />
                      ) : (
                        ""
                      )}
                    </div>
                  )
                }
              }
              if (item.name[0] === "enfant") {
                console.log(item)
                return <DinauxParent data={item.fiche[0].id} key={index} />
              }

              if (item.name[0] === "custom") {
                return <Custom data={data.c} key={index} />
              }

              return true
            })}
          </>
        ) : (
          <>
            <h4>girl dinaux</h4>
            {data.c.component.map((item, index) => {
              if (item.name[0] === "dateBorn") {
                return <Born data={data.c} key={index} />
              }
              if (item.name[0] === "dateDeath") {
                return <Death data={data.c} key={index} />
              }
              if (item.name[0] === "mariage") {
                mariage++
                if (mariage === 1) {
                  return <DinauxMariage data={data.c} key={index} />
                }
              }
              if (item.name[0] === "conjoint") {
                conjoint++
                if (conjoint === 1) {
                  return <DinauxConjoint data={data.c} key={index} />
                }
              }
              if (item.name[0] === "custom") {
                return <Custom data={data.c} key={index} />
              }
              if (item.name[0] === "enfant") {
                console.log(item)
                return <DinauxParent data={item.fiche[0].id} key={index} />
              }
              return true
            })}
          </>
        )}
      </div>
    </div>
  )
}
export const query = graphql`
  query($id: String) {
    c: contentfulFiche(id: { eq: $id }) {
      name
      id
      notDinaux
      gender
      oldestfiche {
        id
      }
      component {
        id: contentful_id
        name
        date
        title
        lieux {
          ville
          pays
          id
        }
        childContentfulComponentContentTextNode {
          content
        }
        reference {
          name
          id
          component {
            name
            title
            reference {
              name
              id
            }
          }
        }
        fiche {
          name
          id
        }
      }
    }
  }
`

export default Fiche
